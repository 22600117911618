import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '@b2b-frontend/components/Button';
import BestSellerIcon from '@b2b-frontend/components/icons/BestSellerIcon';
import QuantityDropdown from '@b2b-frontend/components/QuantityDropdown';
import OutOfStockIcon from '@b2b-frontend/components/icons/OutOfStockIcon';
import LowStockIcon from '@b2b-frontend/components/icons/LowStockIcon';
import noop from '@b2b-frontend/utils/noop';
import { useSituation } from '@b2b-frontend/components/SituationProvider';
import { ROUTES } from '../../constants';
import Heading from '../Heading';
import ProductImagePreview from '../ProductImagePreview';
import { IProductPreviewProps } from 'components/CommonProductTile';
import useBasket from 'utils/useBasket';
import AddToCartButton from 'components/AddToCartButton';

const ProductGridItem: React.FC<IProductPreviewProps> = (
	{
		className,
		showPrices,
		priceElement,
		isOutOfStock,
		onProductClicked,
		product: {
			images = [],
			ean,
			name,
			brand,
			subBrand,
			material,
			href,
			availableQuantity,
			shippingQuantity,
			volume = null,
			isBestSeller,
		},
		addToBasket,
		updateQuantity,
	},
	ref,
) => {
	const { itemMap } = useBasket();
	const [quantity, setQuantity] = useState('');
	const itemInBasket = itemMap[material];
	const isLowStock = availableQuantity > 0 && availableQuantity < 20;
	const { allowAddToCart } = useSituation<{ allowAddToCart: boolean }>();

	const handleAddButtonPressed = async () => {
		if (!quantity) {
			return;
		}

		if (!!itemInBasket) {
			updateQuantity({ material, quantity });
		} else {
			addToBasket({ material, quantity });
		}
	};

	useEffect(() => {
		setQuantity(itemInBasket ? `${itemInBasket.quantity}` : `${shippingQuantity}`);
	}, [itemInBasket, shippingQuantity]);

	const handleProductClick = onProductClicked || noop;

	return (
		<div
			className={clsx(
				'relative bg-white font-sans text-center p-8 flex flex-col grow',
				className,
			)}
			ref={ref}
		>
			<a
				href={href}
				className="flex items-center justify-center mb-8"
				onClick={handleProductClick}
			>
				{showPrices && (
					<>
						{isOutOfStock && (
							<span className="absolute left-0 top-0 transform translate-y-4 z-10 text-white">
								<OutOfStockIcon />
							</span>
						)}
						{!isOutOfStock && Boolean(isBestSeller) && (
							<span className="absolute left-0 top-0 transform translate-y-4 z-10">
								<BestSellerIcon />
							</span>
						)}
						{isLowStock && !isOutOfStock && (
							<span
								className={clsx(
									'absolute left-0 transform translate-y-4 z-10 text-white',
									isBestSeller ? 'top-6' : 'top-0',
								)}
							>
								<LowStockIcon />
							</span>
						)}
					</>
				)}
				<ProductImagePreview images={images} />
				{volume && (
					<span className="text-xs absolute top-50% left-0">
						<span className="mb-2 px-3">{volume}</span>
						<span className="block h-[1px] bg-brand" />
					</span>
				)}
			</a>
			<div className="text-xs grow flex flex-col">
				<Heading as={'h3'} size="text-xs" serif={false} className="font-sans mb-3">
					<a href={href} className="whitespace-normal" onClick={handleProductClick}>
						{name}
					</a>
				</Heading>
				<p>{subBrand}</p>
				<p>{brand}</p>
				<p className="mb-5">{ean}</p>
				{allowAddToCart && (
					<div className="max-w-[230px] mx-auto mt-auto">
						{priceElement && (
							<div className="border-t border-b border-gray py-5 mb-4">
								{priceElement}
							</div>
						)}
						{showPrices ? (
							<div className="flex items-center justify-between">
								<QuantityDropdown
									availableQuantity={availableQuantity}
									className="mr-4"
									value={quantity}
									onChange={setQuantity}
									name={`${material}_quantity`}
									id={`${material}_quantity`}
									disabled={isOutOfStock}
									quantityInterval={shippingQuantity}
									minQuantity={shippingQuantity}
								/>
								<AddToCartButton
									material={material}
									onClick={handleAddButtonPressed}
									isOutOfStock={isOutOfStock}
								/>
							</div>
						) : (
							<Button href={ROUTES.API.AUTH.LOGIN} style={{ width: '160px' }}>
								Login
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default forwardRef(ProductGridItem as any);
